exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-order-order-id-tsx": () => import("./../../../src/pages/order/[orderID].tsx" /* webpackChunkName: "component---src-pages-order-order-id-tsx" */),
  "component---src-templates-browse-talents-tsx": () => import("./../../../src/templates/browseTalents.tsx" /* webpackChunkName: "component---src-templates-browse-talents-tsx" */),
  "component---src-templates-talent-book-tsx": () => import("./../../../src/templates/talentBook.tsx" /* webpackChunkName: "component---src-templates-talent-book-tsx" */),
  "component---src-templates-talent-details-tsx": () => import("./../../../src/templates/talentDetails.tsx" /* webpackChunkName: "component---src-templates-talent-details-tsx" */)
}

